import {
  PiBuilding,
  PiCalendarCheck,
  PiChartBarDuotone,
  PiHouseLineDuotone,
  PiUsers,
} from "react-icons/pi";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { useMemo } from "react";

export const useMenuItems = () => {
  const { isSuperAdmin, isOrgAdmin, hasRole } = useCurrentUser();

  return useMemo(() => {
    const menuItems = [];

    // Dashboard/Hotels item based on role
    menuItems.push({
      name: isSuperAdmin ? "Super Admin Dashboard" : "Hotels",
      href: "/",
      icon: isSuperAdmin ? <PiChartBarDuotone /> : <PiBuilding />,
    });

    // Super Admin specific menu items
    if (isSuperAdmin) {
      menuItems.push(
        {
          name: "Organizations",
          href: "/organizations",
          icon: <PiHouseLineDuotone />,
        },
        {
          name: "Hotels",
          href: "/hotels",
          icon: <PiBuilding />,
        },
        {
          name: "Booking Requests",
          href: "/booking-requests",
          icon: <PiCalendarCheck />,
        },
        {
          name: "Users",
          href: "/users",
          icon: <PiUsers />,
        }
      );
    }

    // Organization Admin specific menu items
    if (isOrgAdmin) {
      menuItems.push(
        {
          name: "Booking Requests",
          href: "/booking-requests",
          icon: <PiCalendarCheck />,
        },
        {
          name: "Users",
          href: "/users",
          icon: <PiUsers />,
        }
      );
    }

    // Regular users with booking request permissions
    if (hasRole('REGULAR_USER') || hasRole('TEAM_LEADER')) {
      menuItems.push({
        name: "Booking Requests",
        href: "/booking-requests",
        icon: <PiCalendarCheck />,
      });
    }

    return menuItems;
  }, [isSuperAdmin, isOrgAdmin, hasRole]);
};
