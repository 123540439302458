import { useSession } from "next-auth/react";
import { useState, useCallback } from "react";
import { User } from "@/types/user";

export function useCurrentUser() {
  const { data: session, status, update } = useSession();
  const user = session?.user as User | undefined;

  const [isUpdating, setIsUpdating] = useState(false);

  const refetchUser = useCallback(async () => {
    if (status === "authenticated") {
      await update();
    }
  }, [status, update]);

  const hasRole = useCallback(
    (roleName: string) => {
      if (!user?.roles) {
        return false;
      }
      
      const hasRole = user.roles.some((userRole: any) => userRole.name === roleName);
      return hasRole;
    },
    [user?.roles]
  );

  return {
    user,
    isLoading: status === "loading",
    isAuthenticated: status === "authenticated",
    isSuperAdmin: hasRole("SUPER_ADMIN"),
    isOrgAdmin: hasRole("ORGANIZATION_ADMIN"),
    isTeamLeader: hasRole("TEAM_LEADER"),
    hasRole,
    refetchUser,
    isUpdating,
  };
}
