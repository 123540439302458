"use client";

import { Title, Text, Avatar, Button, Popover } from "rizzui";
import cn from "@utils/class-names";
import { routes } from "@/config/routes";
import { signOut } from "next-auth/react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "@/app/i18n/client";
import { useCurrentUser } from "@/hooks/useCurrentUser";

export default function ProfileMenu({
  buttonClassName,
  avatarClassName,
  username = false,
  lang,
}: {
  buttonClassName?: string;
  avatarClassName?: string;
  username?: boolean;
  lang?: string;
}) {
  const { user, isLoading } = useCurrentUser();
  const [isOpen, setIsOpen] = useState(false);
  
  useEffect(() => {
    console.log('Popover state:', isOpen);
  }, [isOpen]);

  const handleToggle = useCallback(() => {
    console.log('Toggle clicked, current state:', isOpen);
    setIsOpen(prev => !prev);
  }, [isOpen]);

  if (isLoading) {
    return <div className="w-9 h-9 rounded-full bg-gray-200 animate-pulse" />; 
  }

  if (!user) {
    return <div>Sign In</div>;
  }

  const dropdownContent = (
    <div 
      className="w-64 bg-white rounded-lg shadow-lg overflow-hidden"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex items-center px-6 pb-5 pt-6">
        <Avatar
          src={user.image || ''} 
          name={user.firstName || user.name || ''}
        />
        <div className="ms-3">
          <Title as="h6" className="font-semibold">
            {user.firstName || user.name || ''}
          </Title>
          <Text className="text-gray-600">{user.email || ''}</Text>
        </div>
      </div>
      <div 
        className="px-6 py-4 border-t border-gray-100"
      >
        <Button
          className="h-auto w-full justify-start p-0 font-medium text-gray-700 outline-none focus-within:text-gray-600 hover:text-gray-900 focus-visible:ring-0"
          variant="text"
          onClick={() => signOut()}
        >
          Sign Out
        </Button>
      </div>
    </div>
  );

  return (
    <div className="relative">
      <button
        onClick={handleToggle}
        className={cn(
          "w-9 shrink-0 rounded-full outline-none focus-visible:ring-[1.5px] focus-visible:ring-gray-400 focus-visible:ring-offset-2 active:translate-y-px sm:w-10",
          buttonClassName
        )}
      >
        <div className="flex items-center gap-2 border border-gray-200 rounded-full p-1">
          <Avatar
            src={user.image || ''} 
            name={user.firstName || user.name || ''}
            className={cn("!h-9 w-9 sm:!h-10 sm:!w-10", avatarClassName)}
          />
          {username && (
            <span className="hidden text-sm text-gray-700 md:inline-flex pr-2">
              Hi, {user.firstName || user.name || ''}
            </span>
          )}
        </div>
      </button>

      {isOpen && (
        <div 
          className="absolute right-0 top-full mt-2 z-50 rounded-lg shadow-lg"
          onClick={(e) => e.stopPropagation()}
        >
          {dropdownContent}
        </div>
      )}
    </div>
  );
}
